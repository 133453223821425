import React,{ useState } from 'react';

const Filter = ({ list }) => {
  const [location, setLocation] = useState([]);

  const filteredLocationGym = list.filter((loc) => loc.loc === 'gym').length;
  const filteredLocationHom = list.filter((loc) => loc.loc === 'home').length;
  const filteredLocationPark = list.filter((loc) => loc.loc === 'park').length;
 
  return (
    <div className='filtered'>
      Gym workouts {filteredLocationGym}
      Home workouts {filteredLocationHom}
      Park workouts {filteredLocationPark}
    </div>
  )
}

export default Filter;