import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import { FiDelete } from "react-icons/fi";
import Filter from "./Filter";

const Table = () => {
  const [lists, setLists] = useState([]);
  const [recordlimit, setRecordLimit] = useState(30);

  useEffect(() => {
    firebase
      .firestore()
      .collection("exercises")
      .orderBy("timestamp", "desc")
      .limit(recordlimit)
      .onSnapshot((snapshot) => {
        const lists = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLists(lists);
      });
  }, [recordlimit]);

  const handleOnDelete = (id) => {
    console.log("This is the id: " + id);
    firebase.firestore().collection("exercises").doc(id).delete();
  };

  return (
    <div>
      <Filter list={lists}/>
    <div className="listTable">
      <button onClick={() => setRecordLimit(2000)}>Show all records</button>
      <table>
        <th>Abs</th>
        <th>Back</th>
        <th>Bi</th>
        <th>Cal</th>
        <th>Che</th>
        <th>Delts</th>
        <th>Fore</th>
        <th>Hams</th>
        <th>Quads</th>
        <th>Traps</th>
        <th>Tris</th>
        <th>Date</th>
        <th>Cig</th>
        <th>Skip</th>
        <th>Tread</th>
        <th>Loc</th>
        <th>Hum</th>
        <th>Temp</th>
        <th>KG</th>
        <th>Comments</th>
        {lists.map((list) => {
          return (
            <tr>
              <td>
                <input type="checkbox" name="abs" checked={list.abs} />
              </td>
              <td>
                <input type="checkbox" checked={list.back} />
              </td>
              <td>
                <input type="checkbox" checked={list.biceps} />
              </td>
              <td>
                <input type="checkbox" checked={list.calves} />
              </td>
              <td>
                <input type="checkbox" checked={list.chest} />
              </td>
              <td>
                <input type="checkbox" checked={list.delts} />
              </td>
              <td>
                <input type="checkbox" checked={list.forearms} />
              </td>
              <td>
                <input type="checkbox" checked={list.hams} />
              </td>
              <td>
                <input type="checkbox" checked={list.quads} />
              </td>
              <td>
                <input type="checkbox" checked={list.traps} />
              </td>
              <td>
                <input type="checkbox" checked={list.triceps} />
              </td>
              <td>{list.date}</td>
              <td>
                <input type="text" value={list.cigs} />
              </td>
              <td>
                <input type="text" value={list.skip} />
              </td>
              <td>
                <input type="text" value={list.tread} />
              </td>
              <td>
                <input type="text" value={list.loc} />
              </td>
              <td>
                <input type="text" value={list.hum} />
              </td>
              <td>
                <input type="text" value={list.temp} />
              </td>
              <td>
                <input type="text" value={list.weight} />
              </td>
              <td>
                <textarea value={list.com}>{list.com}</textarea>
              </td>

              <td>
                <button onClick={() =>  handleOnDelete(list.id)}>
                  <FiDelete />
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
    </div>
  );
};

export default Table;
