import React, { useEffect } from 'react';
import logo from './logo.gif';
import './App.css';
import Form from './components/Form';
import firebase from './firebase';
import auth from './firebase';

function App() {
  const provider = new firebase.auth.GoogleAuthProvider();
  useEffect(()=>{
    firebase.auth().signInWithPopup(provider).then(function(result) {
      var token = result.credential.accessToken;
      var user = result.user;
    }).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
      // ...
    },[]);
  })
  return (
    <div className="App">
      
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Gym Tracker
        </h1>
        <p></p>
        
      </header>
      <Form />
    </div>
  );
}

export default App;
